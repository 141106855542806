<template>
    <v-slide-x-transition hide-on-leave>
        <v-btn 
            v-if="!modal" 
            :block="block" 
            @click.stop="open"
            :class="buttonClass"
            :disabled="disabled"
            :icon="icon != null && text == null"
            :large="large"
            :small="small"
            :text="text != null">
            <v-icon 
                v-if="icon != null"
                :class="buttonClass"
                :large="large"
                :left="text != null"
                :small="small">{{ icon }}
            </v-icon>
            <span :class="buttonClass">{{ text }}</span>
        </v-btn>
        <div v-else>
            <v-overlay :value="modal" :opacity="0.75" z-index="1001">
                <v-card :height="fullscreen ? '100vh' : height" :width="fullscreen ? '100vw' : width" :color="transparent ? 'transparent' : ''">
                    <video v-if="photoData == null" ref="video" style="height: 100%; width: 100%;" />
                    <img v-else :src="photoData" />
                    <canvas style="display: none;" ref="canva" />
                    <v-bottom-navigation
                        grow
                        horizontal
                        absolute>
                        <v-btn
                            v-if="photoData == null"
                            @click.stop="close"
                            class="primary"
                            dark
                            icon 
                            key="1"
                            :large="large"
                            :small="small">
                            <v-icon :large="large" :small="small">mdi-arrow-left</v-icon>
                        </v-btn>
                        <v-btn 
                            v-else
                            @click.stop="clear"
                            class="primary"
                            dark
                            icon
                            :large="large"
                            :small="small">
                            <v-icon :large="large" :small="small">mdi-close</v-icon>
                        </v-btn>
                        <v-slide-x-transition hide-on-leave>
                            <v-btn
                                v-if="photoData == null"
                                class="primary"
                                dark
                                @click.stop="takePhoto"
                                icon
                                :large="large"
                                :small="small">
                                <v-icon :large="large" :small="small" color="red">mdi-circle</v-icon>
                            </v-btn>
                        </v-slide-x-transition>
                        
                        <v-spacer key="3" />
                        <v-slide-x-transition hide-on-leave>
                            <v-btn
                                v-if="photoData != null"
                                class="primary"
                                dark
                                @click.stop="save"
                                icon
                                :large="large"
                                :small="small">
                                <v-icon :large="large" :small="small">mdi-check</v-icon>
                            </v-btn>
                            <v-btn 
                                v-else
                                class="primary"
                                dark
                                @click.stop="switchCamera"
                                icon
                                :large="large"
                                :small="small"
                                :disabled="switchingCamera || videoDevices.length < 2">
                                <v-icon :large="large" :small="small">mdi-camera-flip</v-icon>
                            </v-btn>
                        </v-slide-x-transition>
                    </v-bottom-navigation>
                    <v-overlay :value="isLoading" class="text-center">
                        <v-progress-circular indeterminate size="32" />
                        <p>Loading Image</p>
                    </v-overlay>
                </v-card>
            </v-overlay>
        </div>
    </v-slide-x-transition>
</template>

<script>
export default {
    name: 'BT-Camera-Overlay',
    components: {
        //BTCamera: () => import('~components/BT-Camera.vue')
    },
    data: function() {
        return {
            isLoading: false,
            modal: false,
            originalData: null,
            data: null,
            photoData: null,
            mediaStream: null,
            videoDevices: [],
            facingMode: "environment",
            switchingCamera: false,
        }
    },
    props: {
        block: {
            type: Boolean,
            default: false
        },
        buttonClass: {
            type: String,
            default: null
        },
        canEdit: {
            type: Boolean,
            default: true
        },
        canSave: {
            type: Boolean,
            default: false
        },
        color: {
            type: String,
            default: 'black'
        },
        disabled: {
            type: Boolean,
            default: false
        },
        fullscreen: {
            type: Boolean,
            default: false
        },
        height: {
            type: String,
            default: '400px'
        },
        icon: {
            type: String,
            default: 'mdi-draw'
        },
        large: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        },
        onDoOpenAsync: {
            type: Function,
            default: null
        },
        show: {
            type: Boolean,
            default: false
        },
        small: {
            type: Boolean,
            default: true
        },
        text: {
            type: String,
            default: null
        },
        transparent: {
            type: Boolean,
            default: false
        },
        width: {
            type: String,
            default: '100%'
        },
        value: null,
    },
    methods: {
        close() {
            this.stopCameraFeed();
            this.$emit('cancel');
            this.$emit('close');
            this.modal = false;
        },
        async clear() {
            this.photoData = null;
            await this.open();
        }, 
        async open() {
            if (this.onDoOpenAsync != null && !await this.onDoOpenAsync()) {
                return;
            }
            
            this.modal = true;
            this.photoData = null;

            //load original?
            const devices = await navigator.mediaDevices.enumerateDevices();
            this.videoDevices = devices.filter((d) => d.kind === "videoinput");
            
            await this.startRecording(this.videoDevices.length === 1 ? "user" : "environment");
            
            this.$emit('open');
        },
        save() {
            this.stopCameraFeed();
            this.$emit('input', this.photoData);
            this.$emit('change', this.photoData);
            this.$emit('ok', this.photoData);
            this.$emit('close');
            this.modal = false;
        },
        async startRecording(facingMode) {
            this.facingMode = facingMode;
            let video = this.$refs.video;
            this.mediaStream = await navigator.mediaDevices.getUserMedia({
                video: { facingMode: facingMode }
            });

            video.srcObject = this.mediaStream;
            return await video.play();
        },
        stopCameraFeed() {
            const tracks = this.mediaStream.getVideoTracks();
            
            tracks.forEach((track) => {
                track.stop();
            });
        },
        async switchCamera() {
            this.switchingCamera = true;
            this.stopCameraFeed();

            await this.startRecording(this.facingMode === "environment" ? "user" : "environment");

            this.switchingCamera = false;
        },
        async takePhoto() {
            let video = this.$refs.video;
            let canva = this.$refs.canva;
            let width = video.videoWidth;
            let height = video.videoHeight;
            canva.width = width;
            canva.height = height;
            let ctx = canva.getContext("2d");
            ctx.save();
    
            // if (this.facingMode === "user") {
            //     ctx.scale(-1, 1);
            //     //ctx.drawImage()
            //     ctx.drawImage(video, width * -1, 0, width, height);
            // } else {
            ctx.drawImage(video, 0, 0);
            // }
    
            ctx.restore();
    
            this.photoData = canva.toDataURL("image/png");
            
            this.stopCameraFeed();
        }
    }   
}
</script>

<style scoped>
/* @media screen and (min-aspect-ratio: 13/9) {
  .sign-overlay {
    transform: rotate(-90deg);
    transform-origin: left top;
    width: 100vh;
    height: inherit;
    overflow-x: hidden;
    position: absolute;
    top: 100%;
    left: 0;
  }
}
@media screen and (min-width: 1292px) {
  .sign-overlay {
    transform: none;
    transform-origin: none;
    height: 100vh;
    width: 100%;
    overflow-x: none;
    position: relative;
  }
} */
</style>